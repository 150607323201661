header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}
.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}
.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.my{
    width: 25rem;
    height: 10rem;
    margin: 4rem auto;
    overflow: hidden;
}

@media screen and (max-width: 1024px) {
    header{
        height: 68vh;
    }
}
@media screen and (max-width: 600px) {
    header{
        height: 100vh;
    }
    .header__socials{
        display: none;
    }
    .my{
        width: 100%;
        height: auto;
    }
}
